<template>
  <div >
    <!-- <Header @showSideNav="showSideNav" class="sticky-top" /> -->

    <!-- <SideNav id="sideNav" :style="'--p-width: ' + navWidth" :tokendecoded="tokendecoded"/> -->
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import SideNav from "@/components/SideNav.vue";

export default {
  name: "Appframe",
  components: {
    Header,
    SideNav,
  },

  data() {
    return {
      navWidth: 0,
      tokendecoded:'',
      availablemenu:[]
    };

  },
mounted(){
//   var token = document.cookie
// var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));

//      this.tokendecoded = JSON.parse(jsonPayload);
//      console.log(this.tokendecoded);
},
  methods: {
    showSideNav() {
      this.navWidth = !this.navWidth ? "18.313rem" : 0;
    },
  },
};
</script>

<style scoped>
/* #appf {
  background: #f5f5f5;
  height: 100vh;
  overflow-y: scroll;
}

main {
  margin-left: 0;
}

#sideNav {
  height: calc(100vh - 72px);
  width: var(--p-width) !important;
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
  position: absolute;
  left: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
  z-index: 1003;
}

///Large devices (desktops, 992px and up)/
@media (min-width: 992px) {
  #sideNav {
    width: 16rem !important;
  }

  main {
    margin-left: 16rem;
  }
} */
</style>
