(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@Appmart/utility"), require("vue-router"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["@Appmart/utility", "vue-router", "vue"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@Appmart/utility"), require("vue-router"), require("vue")) : factory(root["@Appmart/utility"], root["vue-router"], root["vue"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__60eb__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__8bbf__) {
return 