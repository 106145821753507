<template>
   <div>
    <!-- <Header @showSideNav="showSideNav" class="sticky-top" /> -->

    <SideNav id="sideNav" :style="'--p-width: ' + navWidth" :tokendecoded="tokendecoded" :myMenus="myMenus"/>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
// import {parseJwt,getCookie,getUrl} from '@Appmart/utility'
import axios from "axios"
export default {
  name: "SideBar",
props:['tokendecoded','myMenus'],
 components: {
    SideNav,
  },
 data() {
    return {
      tokendecoded:"",myMenus:""
    }
  },
  // beforeMount() {
  //   let name = getCookie("-X_Zka");
  //   if(!name){
  // window.location.href =`${window.location.origin}/#/auth`;
  //   }
  // this.tokendecoded = parseJwt(name);
  // console.log(this.tokendecoded);
  //   axios.post('https://mycp.unizik.edu.ng/v1/admin/myMenus',{
  //                       staffid: this.tokendecoded.staffid
  //                   }
  //                   ).then(response => {
  //               if(response.data.code =='00'){
  //               this.myMenus = response.data.message;
  //               //to.params.response = response.data;
  //               }
  //             }).catch(e => {
  //             console.log(e);
  //             })
  // },
  mounted(){
   let name = this.getCookie("-X_Zka");
    if(!name){
  window.location.href =`${window.location.origin}/#/auth`;
    }
  this.tokendecoded = this.parseJwt(name);
  console.log(this.tokendecoded);
    axios.post('https://mycp.unizik.edu.ng/v1/admin/myMenus',{
                        staffid: this.tokendecoded.staffid
                    }
                    ).then(response => {
                if(response.data.code =='00'){
                this.myMenus = response.data.message;
                //to.params.response = response.data;
                }
              }).catch(e => {
              console.log(e);
              })
},
methods: {
   getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
},
ShowActivityLoader() {    
  if (document.querySelector("#pleaseWaitDialog") == null) {
      var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
          <div class="modal-dialog" style="background-color:transparent;border:none">\
              <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
                  <div class="modal-body">\
                      <div class="spinner-border" role="status" style="color:#fff">\
  <span class="sr-only"></span>\
</div>\
<div style="color:#fff">Loading ...</div>\
                  </div>\
              </div>\
          </div>\
      </div>';
      $(document.body).append(modalLoading);
  }

  $("#pleaseWaitDialog").modal("show");
},
hideActivityLoader() {
  $("#pleaseWaitDialog").modal("hide");
},

parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
},
    showSideNav() {
      this.navWidth = !this.navWidth ? "18.313rem" : 0;
    },
  },
}

</script>

<style scoped>
/* .ps-4 {
  padding-left: 2.188rem !important;
}

h6 {
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
}

small {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
}

.link-item-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}

.sidebar li .submenu {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.sidebar .nav-link {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}

.sidebar .nav-link:hover {
  color: #fff;
}
ul img{
  color: #fff !important;
}
.sidebar li .submenu{
  padding-left:30px !important
}
#appf {
  background: red;
  height: 100vh;
  overflow-y: scroll;
}

main {
  margin-left: 0;
}

#sideNav {
  height: calc(100vh);
  width: var(--p-width) !important;
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
  position: absolute;
  left: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
  z-index: 1003;
}

Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #sideNav {
    width: 16rem !important;
  }

  main {
    margin-left: 16rem;
  }
} */
</style>