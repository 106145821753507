import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/dashboard'

// import mainDash from '../components/maindash'
// import adminUser from '../components/listCreateAdminList'
// import axios from 'axios'
Vue.use(VueRouter)
// import {getLoggedInUser} from '@Appmart/utility'
// console.log('user', getLoggedInUser());

const routes = [
 
  //{
    //path: '/',
    //name: 'Home',
    //component: Home,
    // children:[
    //   {
    //     path: '/',
    //     name: 'maindash',
    //     component: mainDash,
    //   },
    //   {
    //     path: '/dashboard/adminUser',
    //     name: 'maindash1',
    //     beforeEnter: (to, from, next) => {
    //       axios.post('http://localhost:5003/v1/admin/listadmin')
    //         .then(response => {
    //           if(response.data.code =='00'){
    //           console.log(response.data);
    //           to.params.response = response.data;
    //           next({ params: to.params });
    //           }
    //         })
    //         .catch(e => {
    //           alert('Something went wrong')
    //           next(false)
    //         })
    //     },
    //     component: adminUser,
    //   }
    // ]
  //},
]

const router = new VueRouter({
  // mode: "history",
  routes
})
// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     let name = getCookie("-X_Zka");
//     if(!name){
//   window.location.href = "http://localhost:9000/#/auth/login";
// }else{
//     next()
// }
//   }
// })
export default router
