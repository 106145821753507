<template>
  <header class="bg-white d-flex justify-content-between py-3 ps-3 pe-2">
    <!-- <img src="@/assets/logo.svg" alt="logo" width="300px"/>  -->

    <button @click="showNav" class="btn btn-link d-block d-md-none">
      <img src="@/assets/icons/hamburger.svg" alt="icon" />
    </button>

    <button class="btn btn-link d-none d-md-block">
      <img src="@/assets/icons/bell.svg" alt="icon" />
    </button>
  </header>
</template>

<script>
export default {
  name: "Header",

  methods: {
    showNav() {
      this.$emit("showSideNav", "Awesome");
    },
  },
};
</script>

<style scoped>
header {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.07);
}
</style>
