<template>
<!-- <div class="sidebar">
    <section class="py-4 ps-3 pe-3">
      <div class="d-flex mt-4 mb-3 justify-content-start">
        <img
          class="rounded-circle"
          width="37"
          height="37"
          src="@/assets/img/user.png"
          alt="user image"
        />

        <div class="ms-3">
          <h6 class="m-0">{{datatoken.surname}} {{datatoken.name}}</h6>
          <small class="">{{datatoken.email}}</small>
        </div>
      </div>

      <ul class="nav flex-column" id="nav_accordion" style="padding:0px !important; margin:0px !important">
        <li class="nav-item mt-3">
          <router-link to="/dashboard/maindash" class="nav-link">
            <img src="@/assets/icons/Group.svg" alt="icons"  style="color:#fff !important"/>
            <span class="link-item-text ms-3">Dashboard</span>
          </router-link>
        </li>

        <li class="nav-item mt-3">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target="#menu_item1"
            href="#"
          >
            <img src="@/assets/icons/Vector.svg" alt="icons" />
            <span class="link-item-text mx-3">Bursary</span>
            <i class="bi small bi-caret-down-fill"></i>
          </a>
          <ul
            id="menu_item1"
            class="submenu collapse"
            data-bs-parent="#nav_accordion"
          >           
           <li><router-link to="/bursary/dashboard" class="nav-link"> Dashboard </router-link></li>
            <li><a class="nav-link" href="#">Create Fee </a></li>
            <li><a class="nav-link" href="#">Fee Setup </a></li>
           <li><router-link to="/bursary/FeeAnalysis" class="nav-link"> Fee Analysis </router-link></li>
           <li><router-link to="/bursary/invoices" class="nav-link"> Invoices </router-link></li>

          </ul>
        </li>
        <li class="nav-item mt-3">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target="#menu_item6"
            href="#"
          >
            <img src="@/assets/icons/Vector.svg" alt="icons" />
            <span class="link-item-text mx-3">Admissions</span>
            <i class="bi small bi-caret-down-fill"></i>
          </a>
          <ul
            id="menu_item6"
            class="submenu collapse"
            data-bs-parent="#nav_accordion"
          >           
           <li><router-link to="/admissions/dashboard" class="nav-link"> Dashboard </router-link></li>
            <li><a class="nav-link" href="/admissions/upload">Upload Admission</a></li>
            <li><a class="nav-link" href="/admissions/listUpload">List Upload </a></li>
           <li><router-link to="/admissions/approveAdmission" class="nav-link"> Approve Admission </router-link></li>
           <li><router-link to="/admissions/clearance" class="nav-link"> Clear Students </router-link></li>
           <li><router-link to="/admissions/changeofcourse" class="nav-link"> Change of course </router-link></li>

          </ul>
        </li>
        <li class="nav-item mt-3">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target="#menu_item5"
            href="#"
          >
            <img src="@/assets/icons/Vector.svg" alt="icons" />
            <span class="link-item-text mx-3">Admin</span>
            <i class="bi small bi-caret-down-fill"></i>
          </a>
          <ul
            id="menu_item5"
            class="submenu collapse"
            data-bs-parent="#nav_accordion"
          >           
           <li><router-link to="/admin/users" class="nav-link"> Admin Users </router-link></li>
            <li><a class="nav-link" href="#">Create Fee </a></li>
            <li><a class="nav-link" href="#">Fee Setup </a></li>
           <li><router-link to="/busary/FeeAnalysis" class="nav-link"> Fee Analysis </router-link></li>
           <li><router-link to="/busary/invoices" class="nav-link"> Invoices </router-link></li>

          </ul>
        </li>

        <li class="nav-item mt-3">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target="#menu_item2"
            href="#"
          >
            <img src="@/assets/icons/Vector1.svg" alt="icons" />
            <span class="link-item-text mx-3">Exit Clearance</span>
            <i class="bi small bi-caret-down-fill"></i>
          </a>
          <ul
            id="menu_item2"
            class="submenu collapse"
            data-bs-parent="#nav_accordion"
          >
            <li><a class="nav-link" href="#">Departmental Clearance </a></li>
            <li><a class="nav-link" href="#">Faculty Clearance </a></li>
            <li><a class="nav-link" href="#">Alumni </a></li>
            <li><a class="nav-link" href="#">Library </a></li>
            <li><a class="nav-link" href="#">Bursary </a></li>

          </ul>
        </li>

        <li class="nav-item mt-3">
          <a class="nav-link" href="#">
            <img src="@/assets/icons/invoices.svg" alt="icons" />
            <span class="link-item-text ms-3">Invoices</span>
          </a>
        </li>
      </ul>
    </section>
  </div> -->
<div>
    <v-navigation-drawer v-model="drawer" hide-overlay app style="background-color:#0e4d6a;" :width="300">
        <v-list-item>
            <v-list-item-content class="pa:0">
                <v-list-item-title class="text-h6" style="height:47px; margin:0; padding:0">
                    <v-row>
                        <!-- <div class="col-md-3">
                   <v-img src="../assets/uniziklogo.svg" width="30" class="ma-auto">
                   </v-img>
                        </div> -->
                        <div class="col-md-12 text-center">
                            <div style="color:#fff; font-size:18px"><strong>UNIZIK Central Portal</strong></div>
                            <div style="font-size:12px; color:#fff;margin-top: !important">
                                Control Panel </div>
                        </div>
                    </v-row>
                </v-list-item-title>
                <v-divider></v-divider>
                <v-list-item style="padding-top:10px">
                    <v-avatar color="grey lighten-1" class=" mt-4 text-center" style="text-align:center; margin:auto;">
                        <v-icon dark style="font-size:60px; margin:auto">mdi-account-circle</v-icon>
                        <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png" width="200"></v-img> -->
                    </v-avatar>
                </v-list-item>
                <v-list-item style="color:#fff">
                    <v-list-item-content class="text-h6 text-center">
                        <v-list-item-title class="text-h6 text-center">
                            {{tokendecoded.title}} {{tokendecoded.surname}} {{tokendecoded.name}}
                            <!-- {{userData.surname}} {{userData.names}} -->
                        </v-list-item-title>
                        {{tokendecoded.email}}
                        <!-- <v-list-item-subtitle>{{userData.email}}</v-list-item-subtitle> -->
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-content>

        </v-list-item>

        <v-divider></v-divider>
        <li class="nav-item mt-3" v-for="(menulist, index) in myMenus" :key="index">
            <a class="nav-link" data-bs-toggle="collapse" :data-bs-target="'#menu' + index"  href="#" style="color:white;">
                <v-icon style="color:#fff">mdi-clipboard-check-multiple</v-icon>
               
                <span class="link-item-text mx-3" style="color:white; margin-left:40px"> {{menulist.menu_name}}</span>
                <i class="bi small bi-caret-down-fill"></i>
            </a>
            <ul :id="'menu' + index" class="submenu collapse" data-bs-parent="#nav_accordion">
                <!-- <li><router-link to="/admissions/dashboard" class="nav-link"> Dashboard </router-link></li> -->
                <!-- <li><a class="nav-link" href="/admissions/upload">Upload Admission</a></li> -->
                <!-- <li><a class="nav-link" href="/admissions/listUpload">List Upload </a></li> -->
                <!-- <li><router-link to="/admissions/approveAdmission" class="nav-link"> Approve Admission </router-link></li> -->
                <li v-for="(subMenulist, index) in menulist.sub_menu" :key="index">
                    <router-link :to="subMenulist.submenu_link" class="nav-link" style="color:white; margin-left:40px"> {{subMenulist.submenu_name}} </router-link>
                </li>
                <!-- <li><router-link to="/admissions/changeofcourse" class="nav-link"> Change of course </router-link></li> -->

            </ul>
        </li>

        <!-- <v-list dense nav v-if="userData.validated=='1'">
            <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list> -->
       
    </v-navigation-drawer>

    <v-app-bar app color="#fff" dark prominent style="height:70px !important">
        <!-- <template v-slot:img="{ props }">
            <v-img v-bind="props" gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"></v-img>
        </template> -->

        <v-app-bar-nav-icon @click="drawer = !drawer" style="color:#666"></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <v-app-bar-nav-icon style="color:#666" @click="destroyTohome"><v-icon>mdi-power</v-icon></v-app-bar-nav-icon><br>

                                  

          </v-app-bar>
</div>
</template>

<script>
import {
    getCookie
} from '@Appmart/utility'
import {ShowActivityLoader} from '@Appmart/utility'

export default {
    name: "SideBar",
    props: ['tokendecoded', 'myMenus'],
    data() {
        return {
            drawer: null,
        }
    },
    mounted() {},
    methods:{
      destroyTohome(){
        //ShowActivityLoader()
        document.cookie = '-X_Zka'+'=; Max-Age=-99999999;'; 
                document.cookie = '_-_i_-sz_DS_Ari-Max_mqats676gbk'+'=; Max-Age=-99999999;'; 
 this.$router.go({path:'/auth'})
      }
    }
}
</script>

<style scoped>

</style>
